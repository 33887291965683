import { Button, useTheme } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import React, { ChangeEvent } from "react";

export type ReportAttachmentsTitleCardProps = {
  title: string;
  showUploadButton?: boolean;
  showDeleteButton?: boolean;
};

export const ReportAttachmentsTitleCard: PimoReactComponent<
  ReportAttachmentsTitleCardProps,
  "upload-button:click" | "delete-button:click",
  FileList | null
> = ({ fireEvent, title, showUploadButton, showDeleteButton }) => {
  const theme = useTheme();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files;

    fireEvent?.("upload-button:click", selectedFile);
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      // Trigger the file input click
      fileInputRef.current.click();
    }
  };

  const handleDeleteClick = () => {
    fireEvent?.("delete-button:click");
  };

  return (
    <TitleCard
      title={title}
      endSlot={
        <div style={{ display: "flex", gap: "8px" }}>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            name="myFile"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          {showUploadButton && (
            <Button
              sx={{
                border: "1px solid",
                padding: "4px 16px",
                color: theme.palette.primary.dark,
                borderCollapse: theme.palette.primary.dark,
                borderRadius: "4px",
              }}
              onClick={handleButtonClick}
            >
              Upload
            </Button>
          )}
          {showDeleteButton && (
            <Button
              sx={{
                border: "1px solid",
                padding: "4px 16px",
                color: theme.palette.error.main,
                borderCollapse: theme.palette.primary.dark,
                borderRadius: "4px",
              }}
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          )}
        </div>
      }
    />
  );
};
