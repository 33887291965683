import type PptxGenJS from "pptxgenjs";

import { iamTheme } from "../../theme";
import { fetchOeProjectOverview, fetchOEProjects } from "../fetch-helper";
import { getMaturityColor, getQuarter } from "./helper-functions";
export async function buildFirstSlideOfPPT(pptx: PptxGenJS) {
  const currentQuarter = getQuarter(new Date());
  const endOfYearQuarter = getQuarter(new Date(2025, 11, 31));
  // Add a slide
  const slide = pptx.addSlide();
  const oeProjects = await fetchOEProjects();
  const overview = await fetchOeProjectOverview();
  // Slide Title
  slide.addText("Assessment of OE IAM Maturity Levels", {
    x: 0.5,
    y: 0.7,
    fontSize: 20,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });

  slide.addText("Actuals & Plan", {
    x: 5.35,
    y: 0.7,
    fontSize: 20,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });

  slide.addText("Transformational Journey", {
    x: 0.5,
    y: 0.3,
    fontSize: 11,
    fontFace: "Arial",
    bold: true,
    color: iamTheme.palette.primary.main,
  });

  // Render each row with circles and colors based on IAM Maturity
  slide.addShape(pptx.ShapeType.roundRect, {
    x: 2,
    y: 0.85,
    w: 0.65,
    h: 4,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Actuals ${currentQuarter.quarterAndYear} IAM Maturity`, {
    x: 2,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });

  slide.addShape(pptx.ShapeType.roundRect, {
    x: 2.6,
    y: 0.85,
    w: 0.65,
    h: 4,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Planned ${endOfYearQuarter.quarterAndYear} IAM Maturity`, {
    x: 2.6,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });

  slide.addShape(pptx.ShapeType.roundRect, {
    x: 5.08,
    y: 0.85,
    w: 0.65,
    h: 4,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Actuals ${currentQuarter.quarterAndYear} IAM Maturity`, {
    x: 5.08,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });

  slide.addShape(pptx.ShapeType.roundRect, {
    x: 5.68,
    y: 0.85,
    w: 0.65,
    h: 4,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Planned ${endOfYearQuarter.quarterAndYear} IAM Maturity`, {
    x: 5.7,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,

    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });

  slide.addShape(pptx.ShapeType.roundRect, {
    x: 8.28,
    y: 0.85,
    w: 0.65,
    h: 2,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Actuals ${currentQuarter.quarterAndYear} IAM Maturity`, {
    x: 8.28,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,

    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });

  slide.addShape(pptx.ShapeType.roundRect, {
    x: 8.88,
    y: 0.85,
    w: 0.65,
    h: 2,
    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText(`Planned ${endOfYearQuarter.quarterAndYear} IAM Maturity`, {
    x: 8.9,
    y: 0.9,
    w: 0.62,
    h: 0.5,
    fontSize: 7,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,

    fill: {
      color: iamTheme.palette.secondary.main,
    },
  });
  slide.addText("Division", {
    x: 0.05,
    y: 1.05,
    w: 0.65,
    h: 0.5,
    bold: true,
    fontFace: "Arial",
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });
  slide.addText("Division", {
    x: 3.2,
    y: 1.05,
    w: 0.65,
    h: 0.5,
    bold: true,
    fontFace: "Arial",
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });
  slide.addText("Division", {
    x: 6.4,
    y: 1.05,
    w: 0.65,
    h: 0.5,
    bold: true,
    fontFace: "Arial",
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });
  slide.addText("OEs", {
    x: 0.5,
    y: 1.05,
    w: 0.5,
    h: 0.5,
    bold: true,
    fontFace: "Arial",
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });
  slide.addText("OEs", {
    x: 3.65,
    y: 1.05,
    w: 0.5,
    bold: true,
    fontFace: "Arial",
    h: 0.5,
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });
  slide.addText("OEs", {
    x: 6.85,
    y: 1.05,
    w: 0.5,
    bold: true,
    fontFace: "Arial",
    h: 0.5,
    fontSize: 7,
    color: iamTheme.palette.primary.main,
  });

  const abbreviationsForLongTexts = [
    {
      name: "Pacific Investment Management Company (PIMCO)​",
      abbreviation: "PIMCO",
    },
    {
      name: "Allianz Global Corporate & Specialty (AGCS)​",
      abbreviation: "AGCS",
    },
    { name: "Allianz Investment Management (AIM)​​", abbreviation: "AIM" },
    {
      name: "ADEUS Aktienregister-Service-GmbH​",
      abbreviation: "ADEUS",
    },
  ];

  oeProjects.data
    .filter((_, index) => index < 20)
    .forEach((row, index) => {
      const yPosition = 1.5 + index / 6;

      slide.addShape(pptx.ShapeType.roundRect, {
        x: 0.5,
        y: yPosition - 0.1,
        w: 2.75,
        h: 0.15,

        line: {
          color: "#cccccc",
          width: 1,
          dashType: "solid",
        },
        fill: {
          color: "#ffffff",
        },
      });
      slide.addText(row.attributes.division ?? "", {
        x: 0.15,
        y: yPosition - 0.1,
        fontSize: 8,
        w: 0.35,
        h: 0.15,
        fontFace: "Arial",
        color: "#ffffff",
        fill: {
          color: "#11a0d3",
        },
      });

      slide.addText(
        abbreviationsForLongTexts.find((abbr) =>
          abbr.name.includes(row.attributes.name)
        )?.abbreviation ?? row.attributes.name,
        {
          x: 0.5,
          y: yPosition - 0.025,
          fontFace: "Arial",
          fontSize: 8,
          w: 2.5,
          color: iamTheme.palette.primary.main,
        }
      );
      const actualColor = getMaturityColor(
        Math.round(row.attributes.overallAverageMaturityLevel ?? 1)
      );

      slide.addShape(pptx.ShapeType.ellipse, {
        x: 2.3,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: actualColor,
        },
      });

      const planColor = getMaturityColor(
        Math.round(overview?.OEAndPlannedMaturity2025[row.attributes.name] ?? 1)
      );
      slide.addShape(pptx.ShapeType.ellipse, {
        x: 2.85,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: planColor,
        },
      });
    });

  oeProjects.data
    .filter((_, index) => index >= 20 && index < 40)
    .forEach((row, index) => {
      const yPosition = 1.5 + index / 6;

      slide.addShape(pptx.ShapeType.roundRect, {
        x: 3.65,
        y: yPosition - 0.1,
        w: 2.7,
        h: 0.15,
        line: {
          color: "#cccccc",
          width: 1,
          dashType: "solid",
        },
        fill: {
          color: "#ffffff",
        },
      });
      slide.addText(row.attributes.division ?? "", {
        x: 3.3,
        y: yPosition - 0.1,
        fontSize: 8,
        w: 0.35,
        h: 0.15,
        fontFace: "Arial",
        color: "#ffffff",
        fill: {
          color: "#11a0d3",
        },
      });

      slide.addText(
        abbreviationsForLongTexts.find((abbr) =>
          abbr.name.includes(row.attributes.name)
        )?.abbreviation ?? row.attributes.name,
        {
          x: 3.65,
          y: yPosition - 0.025,
          fontSize: 8,
          fontFace: "Arial",
          w: 2.5,
          color: iamTheme.palette.primary.main,
        }
      );

      const actualColor = getMaturityColor(
        Math.round(row.attributes.overallAverageMaturityLevel ?? 1)
      );
      slide.addShape(pptx.ShapeType.ellipse, {
        x: 5.4,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: actualColor,
        },
      });

      const planColor = getMaturityColor(
        Math.round(overview?.OEAndPlannedMaturity2025[row.attributes.name] ?? 1)
      );
      slide.addShape(pptx.ShapeType.ellipse, {
        x: 6,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: planColor,
        },
      });
    });

  oeProjects.data
    .filter((_, index) => index >= 40)
    .forEach((row, index) => {
      const yPosition = 1.5 + index / 6;

      slide.addShape(pptx.ShapeType.roundRect, {
        x: 6.85,
        y: yPosition - 0.1,
        w: 2.7,
        h: 0.15,
        line: {
          color: "#cccccc",
          width: 1,
          dashType: "solid",
        },
        fill: {
          color: "#ffffff",
        },
      });
      slide.addText(row.attributes.division ?? "", {
        x: 6.5,
        y: yPosition - 0.1,
        fontSize: 8,
        w: 0.35,
        h: 0.15,
        fontFace: "Arial",
        color: "#ffffff",
        fill: {
          color: "#11a0d3",
        },
      });

      slide.addText(
        abbreviationsForLongTexts.find((abbr) =>
          abbr.name.includes(row.attributes.name)
        )?.abbreviation ?? row.attributes.name,
        {
          x: 6.87,
          y: yPosition - 0.025,
          fontSize: 8,
          fontFace: "Arial",
          w: 2.5,
          color: iamTheme.palette.primary.main,
        }
      );
      const actualColor = getMaturityColor(
        Math.round(row.attributes.overallAverageMaturityLevel ?? 1)
      );
      slide.addShape(pptx.ShapeType.ellipse, {
        x: 8.6,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: actualColor,
        },
      });

      const planColor = getMaturityColor(
        Math.round(overview?.OEAndPlannedMaturity2025[row.attributes.name] ?? 1)
      );
      slide.addShape(pptx.ShapeType.ellipse, {
        x: 9.2,
        y: yPosition - 0.08,
        w: 0.11,
        h: 0.11,
        fill: {
          color: planColor,
        },
      });
    });

  slide.addText("IAM Maturity", {
    x: 8.3,
    y: 4.4,
    w: 1.1,
    h: 0.2,
    fontFace: "Arial",
    fontSize: 8,
    color: iamTheme.palette.primary.main,
    line: {
      color: "#cccccc",
      width: 1,
      dashType: "solid",
    },
    fill: {
      color: "#d8dbe1",
    },
  });
  const maturityLevels = [
    { label: "5", y: 4.6, color: getMaturityColor(5) },
    { label: "4", y: 4.8, color: getMaturityColor(4) },
    { label: "3", y: 5.0, color: getMaturityColor(3) },
    { label: "2", y: 5.2, color: getMaturityColor(2) },
    { label: "1", y: 5.4, color: getMaturityColor(1) },
  ];

  const descriptions = [
    { text: ">= 4.5", y: 4.6 },
    { text: ">= 3.5", y: 4.8 },
    { text: ">= 2.5", y: 5.0 },
    { text: ">= 1.5", y: 5.2 },
    { text: "< 1.5", y: 5.4 },
  ];

  // Add maturity level labels
  maturityLevels.forEach(({ label, y, color }) => {
    slide.addText(label, {
      x: 8.3,
      y,
      w: 0.5,
      h: 0.2,
      fontFace: "Arial",
      fontSize: 8,
      line: {
        color: "#cccccc",
        width: 1,
        dashType: "solid",
      },
      fill: { color },
      color: "#000000",
    });
  });

  // Add descriptions
  descriptions.forEach(({ text, y }) => {
    slide.addText(text, {
      x: 8.8,
      y,
      w: 0.6,
      h: 0.2,
      fontSize: 8,
      fontFace: "Arial",
      line: {
        color: "#cccccc",
        width: 1,
        dashType: "solid",
      },
      color: "#000000",
    });
  });
}
