import {
  Box,
  Card,
  ClickAwayListener,
  Dialog,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import { ActivityAttributes, StrapiCollectionEntry } from "iam-types";

export type PopupWithTextContentProps = {
  sectionsToDisplay: { title: string; text: string }[];
  open: boolean;
  cardTitle: string;
};

type PopupWithContentEvent = "popup:open" | "popup:close";

export const PopupWithTextContent: PimoReactComponent<
  PopupWithTextContentProps,
  PopupWithContentEvent,
  StrapiCollectionEntry<ActivityAttributes>
> = ({ cardTitle, open, sectionsToDisplay, fireEvent }) => {
  const theme = useTheme();
  return (
    <Dialog
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: 450,
        width: 650,
        boxShadow: "2px",
        "& .MuiDialog-paper": {
          borderRadius: "12px",
        },
      }}
      hideBackdrop
      open={open}
    >
      <ClickAwayListener onClickAway={() => fireEvent?.("popup:close")}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            height: "100%",
            width: "100%",
            "@media print": {
              border: "1px solid rgba(0, 0, 0, 0.12)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">{cardTitle}</Typography>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
              width: "100%",
            }}
          />

          {sectionsToDisplay.map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  px: 2,
                  overflow: "auto",
                  scrollBehavior: "auto",
                  minHeight: 130,
                  maxHeight: 260,
                  gap: 1,
                  py: 1,
                }}
              >
                <Typography fontWeight={500}>{item.title}</Typography>
                <Markdown>{item.text}</Markdown>
              </Box>
              <Divider
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  height: "1px",
                  borderWidth: 0,
                  width: "100%",
                }}
              />
            </Box>
          ))}
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};
