import { App, UniversalAppState } from "@pimo/pimo-app-builder";
import { saveAs } from "file-saver";
import type { OEProjectAPIAttributes, StrapiCollectionEntry } from "iam-types";
import JSZip from "jszip";
import PptxGenJS from "pptxgenjs";

import { IAMOverlay } from "../components/overlay/iam-overlay";
import {
  type IAMOverlayPartialAppState,
  initialIAMOverlayState,
} from "../components/overlay/iam-overlay-initial-state";
import type { IAMAppState } from "./app";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import {
  fetchAllAttachmentsGroupByProject,
  fetchWithCredentialsAndBlob,
} from "./helpers/fetch-helper";
import { fetchLogs } from "./helpers/fetch-logs";
import { isUserAdmin } from "./helpers/is-user-admin";
import { buildFirstSlideOfPPT } from "./helpers/ppt-export/first-slide";
import { buildFourthSlidePPT } from "./helpers/ppt-export/fourth-slide";
import { buildSecondSlide } from "./helpers/ppt-export/second-slide";
import { buildThirdSlidePPT } from "./helpers/ppt-export/third-slide";

export type OverlayPartialAppState = {
  oeProjects: StrapiCollectionEntry<OEProjectAPIAttributes>[];
  sideMenuEntries: StrapiCollectionEntry<OEProjectAPIAttributes>[];
} & IAMOverlayPartialAppState &
  UniversalAppState;

export const initialOverlayAppState = {
  ...initialIAMOverlayState,
  oeProjects: [],
  isLoading: false,
  sideMenuEntries: [],
} satisfies OverlayPartialAppState;

export const createOverlay = (app: App<IAMAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent({ component: IAMOverlay });

  overlay.on("overlay:toggle-sidebar", () => {
    const state = app.getAppState();

    app.patchAppState({
      isSidebarOpen: !state.isSidebarOpen,
    });
  });

  overlay.on("overlay:click-logo", () => {
    app.navigate(APP_ROUTES.dashboard);
  });

  overlay.on("overlay:show-logs", async () => {
    const logs = await fetchLogs();
    app.patchAppState({
      logs,
    });
  });
  overlay.on("overlay:download-report", () => {
    app.navigate("/pdf", { state: { print: true } });
  });
  overlay.on("overlay:download-all-attachments", async () => {
    try {
      const attachmentsGroupedByProject =
        await fetchAllAttachmentsGroupByProject();

      if (
        !attachmentsGroupedByProject ||
        attachmentsGroupedByProject.length === 0
      ) {
        return;
      }

      const zip = new JSZip();

      for (const project of attachmentsGroupedByProject) {
        const projectFolder = zip.folder(project.project);

        if (projectFolder) {
          for (const attachment of project.attachments) {
            try {
              const blob = await fetchWithCredentialsAndBlob(
                `${STRAPI_URL}${attachment.url}`
              );
              if (blob) {
                projectFolder.file(attachment.name, blob);
              }
            } catch {
              return;
            }
          }
        }
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "attachments.zip");
    } catch {
      return;
    }
  });

  overlay.on("overlay:download-powerpoint", async () => {
    // Create a new presentation
    const pptx = new PptxGenJS();
    try {
      // Add slides to the presentation
      await buildFirstSlideOfPPT(pptx);
      await buildSecondSlide(pptx);
      await buildThirdSlidePPT(pptx);
      await buildFourthSlidePPT(pptx);

      // Save the presentation
      void pptx.writeFile();
    } catch (error) {
      console.error("Error generating PowerPoint presentation:", error);
      return;
    }
  });

  overlay.mapState((state) => ({
    hasSearchFunctionality: true,
    isUserAdmin: isUserAdmin(state.userProfile),

    header: {
      logo: {
        big: "allianz.svg",
        small: "allianz-small.svg",
      },
      username: `${state.userProfile?.name ?? "n/a"} (${
        state.userProfile?.email ?? "n/a"
      }) (${state.userProfile?.fromGiam ? "G" : "S"}: ${state.userProfile?.groups?.join(",") ?? "-"})`,
      enableLogs: true,
      onLogsShow: () => {
        overlay.fireEvent("overlay:show-logs");
      },
    },
    logs: state.logs ?? [],

    menuEntries: [
      {
        icon: "dashboard.svg",
        level: 0,
        link: APP_ROUTES.dashboard,
        title: "Dashboard",
        onlyForAdmin: false,
      },
      {
        icon: "clipboard.svg",
        level: 0,
        link: APP_ROUTES.overview,
        title: "Project Overview",
        onlyForAdmin: false,
      },
      {
        icon: "barchart.svg",
        items:
          state?.sideMenuEntries
            ?.sort((projectA, projectB) =>
              projectA.attributes.name.localeCompare(projectB.attributes.name)
            )
            .map(({ id, attributes: { name } }) => ({
              level: 1,
              link: `/reports/${id ?? 0}/dashboard`,
              title: name,
              active: window.location.pathname.includes(
                `/reports/${id ?? 0}/dashboard`
              ),
            })) ?? [],
        level: 0,
        title: "OE Projects",
      },
    ].filter(
      ({ onlyForAdmin }) =>
        !onlyForAdmin ||
        isUserAdmin(state.userProfile) ||
        state.userProfile?.regionsUserHasAccessTo?.length
    ),
    reportingDate: state.program?.data.attributes.endReportingDate ?? "",
    isSidebarOpen: state.isSidebarOpen,
  }));

  return overlay;
};
