import { Close } from "@mui/icons-material";
import { FilterAlt } from "@mui/icons-material";
import { Box, Divider, FormControl, Typography, useTheme } from "@mui/material";
import { CheckBoxFilter, DropdownFilter } from "@pimo/pimo-components";
import { FilterData, OERegions } from "iam-types";
import {
  FORMATTED_MATURITY_LEVELS,
  getMaturityLevelNumberForMaturityLevel,
} from "iam-utils";
import { FC } from "react";
export interface FilterDialogProps {
  oeProjectsOptions: string[];
  ownersOptions: string[];
  onClick?: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  filterData: FilterData;
}

export const FilterDialog: FC<FilterDialogProps> = ({
  oeProjectsOptions,
  ownersOptions,
  onClick,
  handleChange,
  filterData,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <FilterAlt
            sx={{ color: theme.palette.primary.main, height: "35px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
        </Box>
        <Close onClick={onClick}></Close>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <FormControl
        component="fieldset"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        <CheckBoxFilter
          sectionTitle="Updated / Not Updated"
          options={[
            { label: "Updated", value: "yes" },
            { label: "Not Updated", value: "no" },
          ]}
          triggerIdentifier={"updateStatusFilter"}
          handleChange={handleChange}
          currentValues={filterData.updateStatusFilter}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <CheckBoxFilter
          sectionTitle="Activities at risk?"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          triggerIdentifier={"atRiskFilter"}
          handleChange={handleChange}
          currentValues={filterData.atRiskFilter}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <CheckBoxFilter
          sectionTitle="Activities overdue?"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          triggerIdentifier={"overdueFilter"}
          handleChange={handleChange}
          currentValues={filterData.overdueFilter}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <CheckBoxFilter
          sectionTitle="Maturity (Average)"
          options={FORMATTED_MATURITY_LEVELS.map((maturity) => ({
            label: maturity.formattedMaturity,
            value: getMaturityLevelNumberForMaturityLevel(
              maturity.maturity
            ).toString(),
          }))}
          triggerIdentifier={"maturityFilter"}
          handleChange={handleChange}
          currentValues={filterData.maturityFilter}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <CheckBoxFilter
          sectionTitle="Planned Completion Date"
          options={[
            { label: "2023", value: "2023" },
            { label: "2024", value: "2024" },
            { label: "2025", value: "2025" },
            { label: "2026", value: "2026" },
            { label: "2027", value: "2027" },
          ]}
          triggerIdentifier={"dueYearFilter"}
          handleChange={handleChange}
          currentValues={filterData.dueYearFilter}
          checkBoxProps={{ sx: { minWidth: 90 } }}
        />
        <CheckBoxFilter
          sectionTitle=""
          options={[
            { label: "Q1", value: "q1" },
            { label: "Q2", value: "q2" },
            { label: "Q3", value: "q3" },
            { label: "Q4", value: "q4" },
          ]}
          triggerIdentifier={"dueQuarterFilter"}
          handleChange={handleChange}
          currentValues={filterData.dueQuarterFilter}
        />

        <DropdownFilter
          options={oeProjectsOptions}
          value={filterData.projectsFilter}
          label="OE Projects"
          handleChange={handleChange}
          triggerIdentifier={"projectsFilter"}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <DropdownFilter
          options={ownersOptions}
          value={filterData.govLeadFilter}
          label="IAM Gov LEAD"
          handleChange={handleChange}
          triggerIdentifier={"govLeadFilter"}
        />
        <DropdownFilter
          options={[...OERegions]}
          value={filterData?.regionFilter ?? []}
          label="Regions"
          handleChange={handleChange}
          triggerIdentifier={"regionFilter"}
        />
      </FormControl>
    </Box>
  );
};
