import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import { GridLayout, GridLayoutProps } from "@pimo/pimo-components";

import type { IAMAppState } from "../app";
import { APP_ROUTES } from "../constants";
import { isUserAdmin } from "../helpers/is-user-admin";
import type { DashboardPlugin } from "./dashboard-plugin";
import type { ReportPlugin } from "./report-plugin";

export class PDFDashboardPlugin implements Plugin<IAMAppState, IAMAppState> {
  app?: App<IAMAppState>;
  route?: Route;
  view?: View<IAMAppState, GridLayoutProps>;
  isDashboardLoaded?: boolean;
  constructor(
    private dashboardPlugin: DashboardPlugin,
    private reportPlugin: ReportPlugin
  ) {}

  onRegister(app: App<IAMAppState>): void {
    this.app = app;

    const view = app.createView({
      name: "dashboard",
      layout: new GridLayout(),
    });
    this.view = view;
    this.route = app.createRoute({
      isRenderingAllChildren: true,
      path: APP_ROUTES.pdf,
      view,
    });
  }

  render() {
    if (!this.app || !this.route || !this.view) {
      return;
    }
    const state = this.app.getAppState();

    if (isUserAdmin(state.userProfile) && !this.isDashboardLoaded) {
      this.dashboardPlugin.buildDashboardView(this.view);
      this.isDashboardLoaded = true;
    }
    if (this.route.children.length !== state?.oeProjects.length) {
      this.route.children = [];

      for (const project of state?.oeProjects ?? []) {
        // for each oeProject create a new child route
        const view = this.app?.createView({
          name: `${project?.id ?? 0}`,
          layout: new GridLayout(
            1,
            isUserAdmin(state.userProfile) ||
              state.oeProjects.indexOf(project) !== 0
          ),
        });

        this.reportPlugin.buildDashboardView(view, project.id);

        this.route.createChildRoute({
          path: `${APP_ROUTES.pdf}/${project?.id ?? 0}`,
          view,
        });
      }
    }
  }
}
