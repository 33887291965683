import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import { Log } from "@pimo/pimo-components";
import { Profile } from "iam-types";
import { generateTrendEntriesFromCalculatedTrends } from "iam-utils";

import { APP_KEY } from "../app-key";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import {
  fetchDashboardCalculations,
  fetchOEProjects,
  fetchProgram,
  fetchUserProfile,
} from "./helpers/fetch-helper";
import { isUserAdmin } from "./helpers/is-user-admin";
import {
  createOverlay,
  initialOverlayAppState,
  type OverlayPartialAppState,
} from "./overlay";
import {
  DashboardPlugin,
  type DashboardPluginPartialAppState,
} from "./plugins/dashboard-plugin";
import { HomePlugin } from "./plugins/home-plugin";
import { NoDataPlugin } from "./plugins/no-data.plugin";
import { OverviewAppState, OverviewPlugin } from "./plugins/overview-plugin";
import { PDFDashboardPlugin } from "./plugins/pdf-dashboard-plugin";
import {
  EditPagesAppState,
  ReportPlugin,
  ReportPluginPartialAppState,
} from "./plugins/report-plugin";
import { iamTheme } from "./theme";

export type IAMAppState = OverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  DashboardPluginPartialAppState &
  OverviewAppState &
  ReportPluginPartialAppState &
  EditPagesAppState &
  UniversalAppState & {
    logs?: Log[];
  };

// Plugins
const loginPlugin = new LoginPlugin<IAMAppState["userProfile"], IAMAppState>(
  "/",
  STRAPI_URL,
  "IAM Rollout Tracking"
);

const homePlugin = new HomePlugin<IAMAppState>();
const dashboardPlugin = new DashboardPlugin();
const overviewPlugin = new OverviewPlugin();
const reportPlugin = new ReportPlugin();
const pdfDashboardPlugin = new PDFDashboardPlugin(
  dashboardPlugin,
  reportPlugin
);
// APP
const app = App.create<IAMAppState>(
  composeInitialState<IAMAppState>({
    ...initialOverlayAppState,
    ...loginPlugin.getInitialState(),
    isLoading: true,
  }),
  APP_KEY
);
app.setTheme(iamTheme);

const overlay = createOverlay(app);
app.registerPlugin(loginPlugin);
app.registerPlugin(dashboardPlugin);
app.registerPlugin(homePlugin);
app.registerPlugin(overviewPlugin);
app.registerPlugin(reportPlugin);
app.registerPlugin(pdfDashboardPlugin);
app.registerPlugin(new NoDataPlugin<IAMAppState>());

// LIFECYCLES
overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

dashboardPlugin.route?.on("load", async () => {
  const [oeProjects, program, dashboard] = await Promise.all([
    fetchOEProjects(),
    fetchProgram(),
    fetchDashboardCalculations(),
  ]);

  app.patchAppState({
    dashboard,
    oeProjects: oeProjects.data ?? [],
    program,
    overallTrends: {
      overall: {
        actuals: dashboard?.trends?.overallActuals ?? [],
        planned: dashboard?.trends?.overallPlanned ?? [],
        recommended: dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      },
      model: {
        actuals: dashboard?.trends?.modelActuals ?? [],
        planned: dashboard?.trends?.modelPlanned ?? [],
        recommended: dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      },
      gov: {
        actuals: dashboard?.trends?.govActuals ?? [],
        planned: dashboard?.trends?.govPlanned ?? [],
        recommended: dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      },
      tech: {
        actuals: dashboard?.trends?.techActuals ?? [],
        planned: dashboard?.trends?.techPlanned ?? [],
        recommended: dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      },
    },
    currentTrends: {
      overall: generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: dashboard?.trends?.overallActuals ?? [],
        calculatedPlanned: dashboard?.trends?.overallPlanned ?? [],
        calculatedRecommended:
          dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      }),
      model: generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: dashboard?.trends?.modelActuals ?? [],
        calculatedPlanned: dashboard?.trends?.modelPlanned ?? [],
        calculatedRecommended:
          dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      }),
      gov: generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: dashboard?.trends?.govActuals ?? [],
        calculatedPlanned: dashboard?.trends?.govPlanned ?? [],
        calculatedRecommended:
          dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      }),
      tech: generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: dashboard?.trends?.techActuals ?? [],
        calculatedPlanned: dashboard?.trends?.techPlanned ?? [],
        calculatedRecommended:
          dashboard?.trends?.recommendedMaturitiesOverall ?? [],
      }),
    },
    trendFilter: {
      overall: {
        trendScale: "monthly",
        trendYear: new Date().getFullYear(),
      },
      model: { trendScale: "monthly", trendYear: new Date().getFullYear() },
      gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
      tech: { trendScale: "monthly", trendYear: new Date().getFullYear() },
    },
  });

  if (oeProjects.data.length === 0) {
    app.navigate(`/no-data`);
  }
});

homePlugin.route?.on("load", async () => {
  const isLoggedIn = await loginPlugin.isLoggedIn();

  if (isLoggedIn) {
    // if route is loaded and user is not admin, redirect them to the first OE report
    if (!isUserAdmin(app.getAppState().userProfile)) {
      if (app.getAppState().userProfile?.regionsUserHasAccessTo?.length) {
        app.navigate(APP_ROUTES.dashboard);
      } else {
        const oeProjects = await fetchOEProjects();
        if (oeProjects.data.length > 1) {
          app.navigate(APP_ROUTES.overview);
        } else if (oeProjects.data.length > 0) {
          app.navigate(`/reports/${oeProjects.data?.[0]?.id ?? 0}/dashboard`);
        } else {
          app.navigate(`/no-data`);
        }
      }
    } else {
      app.navigate(APP_ROUTES.dashboard);
    }
  } else {
    app.setAppState({ ...app.getAppState(), isLoading: false });
    app.navigate(APP_ROUTES.login);
  }
});

pdfDashboardPlugin.route?.on("load", () => {
  // check if `history.state` is populated.
  // `{ usr? {} }` is injected via `react-router-dom`.
  if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
    window.onafterprint = () => {
      if (window.location.pathname.includes("pdf")) {
        // eslint-disable-next-line
        // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
        app.navigate(-1, { state: null });
      }
    };
    // wait until the main thread is clear.
    // this also includes assets that are being fetched.
    window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
  }
});

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    return;
  }

  const [dashboard, oeProjects, program, userProfile] = await Promise.all([
    fetchDashboardCalculations(),
    fetchOEProjects(),
    fetchProgram(),
    fetchUserProfile(),
  ]);

  const state = app.getAppState();

  app.setAppState({
    ...state,
    isLoading: false,
    dashboard,
    oeProjects: oeProjects.data ?? [],
    sideMenuEntries: oeProjects.data ?? [],
    program,
    userProfile,
  });

  // lazily populate the pdf page after fetching all data
  pdfDashboardPlugin.render();
});

const RenderedApp = app.render();

export default RenderedApp;
