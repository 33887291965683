import { Tune } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  useTheme,
} from "@mui/material";
import { FilterDataRegionFilter } from "iam-types";
import React from "react";

import { FilterDialogDashboard } from "./filter-dialog-dashboard";

export type DashboardTitleCardSearchBarSlotProps = {
  filterData?: FilterDataRegionFilter;
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;

  filterValues?: { regions?: string[] };
  clearFilters: () => void;
};

export const DashboardTitleCardSearchBarSlot = ({
  filterData,
  clearFilters,
  handleFilterChange,
  filterValues,
}: DashboardTitleCardSearchBarSlotProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [open, setOpen] = React.useState(false);
  const clickAwayHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#FCFCFC",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.info.light,
            color: "#122B54",
            marginRight: 1.5,
            cursor: "pointer",
            verticalAlign: "middle",
            borderRadius: "8px",
            p: 0.5,
            height: "30px",
            width: "30px",
          }}
          onClick={(e) => {
            setOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          <Tune />
        </Box>
      </Box>
      {open && (
        <ClickAwayListener
          onClickAway={clickAwayHandler}
          mouseEvent={"onMouseUp"}
        >
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{
              backgroundColor: "background.paper",
              borderColor: theme.palette.primary.main,
              boxShadow: "0px 0px 5px grey",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1,
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <Box>
              <FilterDialogDashboard
                onClick={() => {
                  setOpen(false);
                }}
                handleChange={handleFilterChange}
                filterData={filterData}
                filterValues={filterValues}
              />
              <Button
                onClick={clearFilters}
                sx={{
                  backgroundColor: "#0069d9",
                  height: "56px",
                  width: "300px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0075d9",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                Clear All
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};
