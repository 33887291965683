import { FileDownloadOutlined, Logout } from "@mui/icons-material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  DefaultOverlayEventPayload,
  type DefaultOverlayProps,
} from "@pimo/pimo-components";

// import { OverlayNotificationsButton } from "../overlay-notifications-button/overlay-notifciations-button";
import { STRAPI_URL } from "../../app/env";

export type IAMOverlayProps = DefaultOverlayProps & {
  reportingDate?: string;
  isUserAdmin?: boolean;
};

export type IAMOverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:toggle-date-picker"
  | "overlay:download-report"
  | "overlay:download-excel"
  | "overlay:download-all-attachments"
  | "overlay:download-powerpoint";

type IAMOverlayEventPayload =
  | { reportingDate: string }
  | DefaultOverlayEventPayload;

/**
 * Extends the {@link DefaultOverlay} by adding a half-year dropdown field next to the account menu
 */
export const IAMOverlay: PimoReactComponent<
  IAMOverlayProps,
  IAMOverlayEventNames,
  IAMOverlayEventPayload
> = ({ fireEvent, isUserAdmin, ...props }) => {
  const entries = [];

  if (isUserAdmin) {
    entries.push({
      icon: FileDownloadOutlined,
      onClick: () => fireEvent?.("overlay:download-all-attachments"),
      text: "Download All Attachments",
    });
    entries.push({
      text: "Download Powerpoint Report",
      icon: FileDownloadOutlined,
      onClick: () => fireEvent?.("overlay:download-powerpoint"),
    });
  }

  entries.push(
    {
      icon: FileDownloadOutlined,
      onClick: () => fireEvent?.("overlay:download-report"),
      text: "Download PDF Report",
    },
    {
      text: "Download Excel Report",
      icon: FileDownloadOutlined,
      onClick: () => window.open(`${STRAPI_URL}/api/export`),
    },

    {
      icon: Logout,
      onClick: () => fireEvent?.("overlay:logout"),
      text: "Logout",
    }
  );

  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    entries,
    /** @todo: this should be commented in once the functionality becomes relevant */
    // children: <OverlayNotificationsButton />,
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
