import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IAM_COLORS } from "iam-utils";
import { type MouseEventHandler, useState } from "react";

import {
  ReportingDateIndicator,
  type ReportingDateIndicatorProps,
} from "../reporting-date-indicator/reporting-date-indicator";

export type ReportTitleCardEditSlotProps = {
  members: TeamMember[];
  onClick: MouseEventHandler<HTMLButtonElement>;
  showEditButton?: boolean;
} & { reportingDateIndicator: ReportingDateIndicatorProps };

interface TeamMember {
  name: string;
  position: string;
}

type TeamProps = {
  members: TeamMember[];
};

const TeamOverlay = styled((props: TeamProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        {...props}
        id="team-popover"
        style={{ backgroundColor: theme.palette.primary.main }}
        onClick={handleClick}
      >
        <Box
          component="img"
          src="group-outlined.svg"
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: "30px",
          }}
        />
      </IconButton>
      <Popover
        id="team-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: 12,
            },
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
            p: 2,
          }}
        >
          Team
        </Typography>
        {props.members.map((member) => (
          <Box
            key={member.name}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", pr: 5 }}>
              <Box
                component="img"
                src="team-member-filled.svg"
                sx={{ marginRight: 1, height: "30px" }}
              />{" "}
              {member.name}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                label={member.position}
                sx={{ backgroundColor: IAM_COLORS.babyBlue, color: "white" }}
              />
            </Box>
          </Box>
        ))}
      </Popover>
    </>
  );
})({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50px",
  height: 32,
  width: 32,
});

type EditButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLocked?: boolean;
};

const EditButton = ({ isLocked, onClick }: EditButtonProps) => {
  const theme = useTheme();
  const button = (
    <Button
      disabled={isLocked}
      sx={{
        border: "1px solid",
        padding: "4px 16px",
        color: theme.palette.primary.dark,
        borderCollapse: theme.palette.primary.dark,
        borderRadius: "4px",
      }}
      onClick={onClick}
    >
      Edit {isLocked && "🔒"}
    </Button>
  );

  if (!isLocked) {
    return button;
  }

  return (
    <Tooltip title={`Locked by ...`}>
      {/* We need to render the `div` to make the tooltip work with the disabled button */}
      <div>{button}</div>
    </Tooltip>
  );
};

export const ReportTitleCardEditSlot = ({
  members,
  onClick,
  reportingDateIndicator,
  showEditButton,
}: ReportTitleCardEditSlotProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
      }}
    >
      <TeamOverlay members={members} />
      <ReportingDateIndicator {...reportingDateIndicator} />
      {showEditButton && <EditButton onClick={onClick} />}
    </Box>
  );
};
