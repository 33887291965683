import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { createTheme } from "@mui/material";

export const iamTheme = createTheme({
  typography: {
    fontFamily: "Roboto",
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
    },
    h6: {
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#003781",
    },
    secondary: {
      main: "#DFEFF2",
      dark: "#B5DAE6",
      contrastText: "#003781",
    },
    info: {
      light: "#EDE7F6",
      main: "#EDE7F6",
    },
    success: {
      light: "#00C85333",
      main: "#00C853",
    },
    warning: {
      light: "#ffeb3b80",
      main: "#ffeb3b",
    },
  },
});
