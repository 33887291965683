import type PptxGenJS from "pptxgenjs";

import { iamTheme } from "../../theme";
import { fetchDashboardCalculations, fetchOEProjects } from "../fetch-helper";
import {
  getEndOfQuartersBetweenDates,
  getLast12Months,
} from "./helper-functions";
export async function buildThirdSlidePPT(pptx: PptxGenJS) {
  const thirdSlide = pptx.addSlide();
  const oeProjects = await fetchOEProjects();
  const dashboard = await fetchDashboardCalculations();

  thirdSlide.addText("Transformational Journey", {
    x: 0.5,
    y: 0.3,
    fontSize: 11,
    fontFace: "Arial",
    bold: true,
    color: iamTheme.palette.primary.main,
  });

  thirdSlide.addText(
    "IAM TOM implementation monitored via IAM Maturity Levels shows progres across Allainz Group",
    {
      x: 0.5,
      y: 0.7,
      fontFace: "Arial",
      fontSize: 20,
      w: 7,
      color: iamTheme.palette.primary.main,
    }
  );

  const date = new Date();
  const months = getLast12Months(date);
  const data = [
    {
      name: "Very Low",
      labels: months.map((m) => m.month),
      values: months.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 1.5
            )
          ).length
      ),
    },
    {
      name: "Low",
      labels: months.map((m) => m.month),
      values: months.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 2.5 &&
                t.attributes.overallMaturity >= 1.5
            )
          ).length
      ),
    },
    {
      name: "Moderate",
      labels: months.map((m) => m.month),
      values: months.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 3.5 &&
                t.attributes.overallMaturity >= 2.5
            )
          ).length
      ),
    },
    {
      name: "High",
      labels: months.map((m) => m.month),
      values: months.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 4.5 &&
                t.attributes.overallMaturity >= 3.5
            )
          ).length
      ),
    },
    {
      name: "Very High",
      labels: months.map((m) => m.month),
      values: months.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity >= 4.5
            )
          ).length
      ),
    },
  ];

  // Add the chart to the slide
  thirdSlide.addChart(pptx.ChartType.bar, data, {
    x: 0.5, // X position on slide
    y: 2.1, // Y position on slide
    w: 4, // Width of the chart
    h: 3, // Height of the chart
    barGrouping: "stacked", // Stacked bars
    barDir: "vertical", // Horizontal bars
    dataLabelColor: "000000", // White color for data labels
    dataLabelFontSize: 8, // Font size for data labels
    showLegend: true, // Show legend
    title: "Stacked Bar Chart Example",
    titleFontSize: 18,
    titleAlign: "center",
    catAxisLineShow: false, // Hides the category axis grid lines
    valAxisLineShow: false, // Hides the value axis grid lines
    valGridLine: { color: "FFFFFF" }, // Optional: Explicitly set grid line color to invisible
    chartColors: ["#65ac57", "#a7ce5c", "#fdfe3f", "#ecc02f", "#d7230e"],
    legendPos: "t",
    valAxisLabelColor: iamTheme.palette.primary.main,
    catAxisLabelColor: iamTheme.palette.primary.main,
    showLabel: true,
    showValue: true,
  });
  const quarters = getEndOfQuartersBetweenDates(
    new Date("2023-09-09"),
    new Date("2025-12-31")
  );
  const dataChartLine2 = [
    {
      name: "Actuals",
      labels: quarters.map((q) => q.quarter),
      values: quarters.map(
        (q) =>
          dashboard?.trends?.overallActuals.find(
            (t) => t.year === q.year && t.month === q.number
          )?.maturity ?? null
      ),
    },
    {
      name: "Forecast",
      labels: quarters.map((q) => q.quarter),
      values: quarters.map((q, index) =>
        dashboard?.trends?.overallActuals.find(
          (t) =>
            t.year === quarters[index - 1]?.year &&
            t.month === quarters[index - 1]?.number
        )?.maturity
          ? null
          : dashboard?.trends?.overallPlanned.find(
              (t) => t.year === q.year && t.month === q.number
            )?.maturity
      ),
    },
  ];

  thirdSlide.addChart(pptx.ChartType.line, dataChartLine2, {
    x: 4.8, // X position on the slide
    y: 2.1, // Y position on the slide
    w: 4.5, // Width of the chart
    h: 3, // Height of the chart
    showLabel: true,
    valAxisMinVal: 1,
    valAxisMajorUnit: 0.5,
    valAxisMaxVal: 5,
    titleFontSize: 10,
    dataLabelFormatCode: "0.0",
    dataLabelPosition: "t",
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    showTitle: false,
    showLegend: true, // Show legend
    legendPos: "t",
    showValAxisTitle: true,
    valAxisLabelColor: iamTheme.palette.primary.main,
    catAxisLabelColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 8,
    catAxisLabelFontSize: 8,
    showValue: true,
  });
}
